import sound from '../../imgs/ravenclaw-quiz.mp3';
import staticSound from "../../imgs/static.mp3";

const ljud = new Audio();

const playSound = async () => {
    ljud.loop = false;
    ljud.src = sound;
    await ljud.play();
}

const playStatic = async () => {
    ljud.loop = true;
    ljud.src = staticSound
    await ljud.play();
}

const stopSound = () => {
    if (ljud.src) {
        ljud.pause();
        ljud.src = null;
    }
}

export { playStatic, playSound, stopSound };