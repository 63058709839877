import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import axios from 'axios';
import { getEntrance } from "../../Helpers/QuizHelpers/getEntrance";
import {stopSound} from "../../Helpers/QuizHelpers/playSound";

const api = process.env.REACT_APP_API;

const getQuestions = async (quizId) => {
    const tempQuiz = quizId.toLowerCase();
    const quiz = tempQuiz.charAt(0).toUpperCase() + tempQuiz.slice(1);
    const questions = await axios.get(`${api}/quizquestions`, {
        params: {
            parentQuiz: quiz,
        },
    });
    return questions.data;
};

const getAnswer = async (form, quizId) => {
    const tempQuiz = quizId.toLowerCase();
    const quiz = tempQuiz.charAt(0).toUpperCase() + tempQuiz.slice(1);
    const coordinates = await axios.post(`${api}/quizquestions/sendanswers`, {
        form,
        parentQuiz: quiz,
    });
    return coordinates.data.correct;
}

const QuizPage = () => {

    const [questions, updateQuestions] = useState([]);
    const [activeQuestion, setActiveQuestion] = useState(0);
    const [answerForm, setAnswerForm] = useState([]);
    const [answerKey, setAnswerKey] = useState([]);
    const [updateCheckboxes, update] = useState(false);
    const [enterHouse, setEnterHouse] = useState(false);

    const [coordinates, setCoordinates] = useState(undefined);

    let location = useLocation();
    let quizId = location.hash.substring(1);

    const setInitialState = () => {
        async function fetchQuestions() {
            const c = await getQuestions(quizId);
            updateQuestions(c);
            setActiveQuestion(0);
            setAnswerForm([]);
            setAnswerKey([]);
            setEnterHouse(false);
            setCoordinates(undefined);
        }
        fetchQuestions();
    };

    useEffect( () => {
        setInitialState();
    }, [quizId]);

    useEffect(() => {
        if (!coordinates) {
            setInitialState()
        }
    }, [coordinates]);

    const giveAnswer = (index) => {
        const key = answerKey;
        if (key.includes(index)) {
            key.splice(key.indexOf(index), 1);
        } else {
            key.push(index);
        }
        setAnswerKey(key);
        update(!updateCheckboxes);
    };

    const nextQuestion = async () => {
        const question = questions[activeQuestion];
        question.key = answerKey;
        const form = answerForm;
        form.push(question);
        setAnswerForm(form);
        setAnswerKey([]);
        if (activeQuestion === questions.length - 1) {
            const correct = await getAnswer(answerForm, quizId);
            updateQuestions([]);
            setEnterHouse(correct);
            if (!correct) {
                setInitialState();
            }
            return;
        }
        setActiveQuestion(activeQuestion + 1);
    };

    const entrance = getEntrance(quizId);

    return coordinates ? (
            coordinates
    ) : enterHouse ? (entrance({answerForm, setCoordinates, setEnterHouse})) : (
        <div
            style={{
                width: '100%',
                alignItems: 'center',
                justifyContent: 'center',
                display: 'flex',
                flexDirection: 'column',
            }}
        >
            <div>
                {!!(questions && questions.length && questions[activeQuestion]) && questions[activeQuestion].question}
            </div>
            <div
                style={{
                    width: '100%',
                    display: 'flex',
                    flexDirection: 'column',
                }}
            >
                <div
                    style={{
                        height: '28vh',
                    }}
                >
                {!!(questions && questions.length && questions[activeQuestion]) && questions[activeQuestion].answers.map((answer, index) => {
                    return (
                        <div
                            key={index}
                            onClick={giveAnswer.bind(this, index)}
                            style={{
                                border: 2,
                                backgroundColor: 'white',
                                marginTop: 2,
                                marginBottom: 2,
                                marginRight: 1,
                                marginLeft: 1,
                                color: 'black',
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center',
                                padding: 8,
                            }}
                        >
                            <div
                                style={{
                                    flex: 1,
                                }}
                            >
                                {answer}
                            </div>
                            <input
                                style={{
                                    transform: 'scale(1.5)',
                                    marginRight: 12,
                                }}
                                checked={answerKey.includes(index)}
                                type="checkbox"
                                onChange={() => {
                                    return;
                                }}
                            />
                        </div>
                    )
                })}
                </div>
                {
                    !!(questions && questions.length && questions[activeQuestion]) && (
                        <div>
                            <button
                                style={{
                                    flex: 0,
                                }}
                                onClick={nextQuestion}
                            >
                                nästa fråga
                            </button>
                        </div>
                    )
                }
            </div>
        </div>
    )
};

export default QuizPage;
