import axios from "axios";
import { stopSound } from "./playSound";
const api = process.env.REACT_APP_API;

const sendEnterSequence = async (enterSequence, props, house) => {
    const { answerForm, setCoordinates } = props;
    const result = await axios.post(`${api}/quizquestions/sendsequence`, {
        parentQuiz: house,
        form: answerForm,
        enterSequence,
    });
    if (result.data.nextSequence) {
        return result.data.nextSequence;
    }
    setCoordinates(result.data.coordinates);
    stopSound();
};

export { sendEnterSequence };
