import React, {useEffect, useState} from 'react';
import Webcam from "react-webcam";
import {acceptableLetters} from "../../Helpers/QuizHelpers/acceptableLetters";
import axios from "axios";
const api = process.env.REACT_APP_API;

const Erised = () => {
  const [opacity, setOpacity] = useState(0);
  const [answer, setAnswer] = useState('');
  let enterSequence = '';
  const handleKeyDown = async (e) => {
    if (e.keyCode === 13) {
console.log(enterSequence);      
const result = await axios.get(`${api}/erisedAnswer`, {
      params: {  answerSequence: enterSequence,},
      });
      setAnswer(result.data.answer);
      setOpacity(1);
      setTimeout(() => {
        setAnswer('');
        setOpacity(0)
      }, 10000);
    }
    if (!acceptableLetters.includes(e.key)) {
      return;
    }
    enterSequence += e.key.toLowerCase();
  };

  window.onkeydown = handleKeyDown;
  return <div
    style={{
      height: 800,
      width: 400,
      overflow: 'hidden',
      borderRadius: 100,
      display: 'flex',
      flexDirection: 'column',
    }}
  >
    <div
      style={{
        position: "absolute",
        alignSelf: 'center',
        bottom: 120,
        visibility: opacity ? 'visible' : 'hidden',
      }}
    >
      {answer}
    </div>
    <Webcam
      videoConstraints={{
        height: 800,
        width: 400,
      }}
    />
  </div>
};

export default Erised;
