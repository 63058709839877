import { useState, useEffect } from 'react';
import axios from "axios";
import christmas from '../../imgs/christmas.jpeg';
const api = process.env.REACT_APP_API;

const getDate = async() => {
    const date = await axios.get(`${api}/getChristmasGift`);
    return date;
}

const ChristmasPage = () => {
    const [coords, setCoords] = useState('');
    useEffect(() => {
        async function callAPI () {
            const updatedCoords = await getDate();
            setCoords(updatedCoords.data.coordinates);
        }
        callAPI();
    }, [])
    return (
        <div>
            <img src={christmas} />
            {coords && (
                <div>
                    {coords.toString()}
                </div>
            )}
        </div>
    )
}

export { ChristmasPage }