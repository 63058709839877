import React, { useState, useEffect } from 'react';
import axios from 'axios';
import './ReverseWIGPage.css';

const api = process.env.REACT_APP_API;

const fields = [
    {
        code: 'firstCode',
    },
    {
        code: 'secondCode',
    },
    {
        code: 'thirdCode',
    }
];

const fetchCoords = async (firstCode, secondCode, thirdCode) => {
    const coords = await axios.post(`${api}/rev`, {a: firstCode, b: secondCode, c: thirdCode})
    const res = coords.data;
    return `n ${res[0]} e ${res[1]}`;
};

const ReverseWIGPage = () =>{
    const [coordinates, setCoordinates] = useState('');
    const [firstCode, setFirstcode] = useState('');
    const [secondCode, setSecondcode] = useState('');
    const [thirdCode, setThirdcode] = useState('');
    const codeMethods = [
        setFirstcode,
        setSecondcode,
        setThirdcode,
    ];

    const textChange = (index, text) => {
        const codeToState = text.target.value;
        codeMethods[index](codeToState);
    };

    useEffect(() => {
        if (firstCode.length === 6 && secondCode.length === 6 && thirdCode.length === 6) {
            const calculate = async () => {
                const c = await fetchCoords(firstCode.toString(), secondCode.toString(), thirdCode.toString());
                setCoordinates(c);
            };
            calculate();
        }
    }, [firstCode, secondCode, thirdCode]);


    return (
        <div
            className="rev-wig-container"
        >
            {
                !!coordinates.length && (
                    <div>
                        {coordinates}
                    </div>
                )
            }
            {
                fields.map((field, index) => {
                    return (
                        <input
                            key={index}
                            type="text"
                            maxLength={6}
                            onChange={textChange.bind(this, index)}
                        />
                    )
                })
            }
        </div>
    )
};

export default ReverseWIGPage;

