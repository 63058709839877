import React, { useState, useEffect } from 'react';
import axios from 'axios';

const api = process.env.REACT_APP_API;

const getNotes = async () => {
    const notes = await axios.get(`${api}/notes`);
    return notes.data;
};

const createNoteCall = async (text, title) => {
    await axios.post(`${api}/notes`, {text, title});
};

const NotePage = () => {
    const [needsReload, reload] = useState(true);
    const [notes, setNotes] = useState([]);
    const [text, setText] = useState('');
    const [title, setTitle] = useState('');
    useEffect(() => {
        async function fetchNotes() {
            const notesFromServer = await getNotes();
            setNotes(notesFromServer);
        }
        fetchNotes();
    }, [needsReload]);

    const createNote = async () => {
        await createNoteCall(text, title);
        reload(!needsReload);
    };

    const changeTitle = (e) => {
        setTitle(e.target.value);
    };

    const changeText = (e) => {
        setText(e.target.value);
    };

    return (
        <div
            style={{
                display: 'flex',
                flexDirection: 'row',
                flex: 1,
                width: '100%',
            }}
        >
            <div
                style={{
                    display: 'flex',
                    flexDirection: 'column',
                }}
            >
                text
                <input
                    type="text"
                    onChange={changeTitle}
                />
                body
                <input
                    type="text"
                    onChange={changeText}
                />
                <button
                    onClick={createNote}
                >
                    send
                </button>
            </div>
            <div
                style={{
                    flex: 1,
                    display: 'flex',
                    alignItems: 'center',
                    flexDirection: 'column',
                }}
            >
            {
                notes.map((note, index) => {
                    return (
                        <div
                            key={index}
                            style={{
                                backgroundColor: 'white',
                                width: '100%',
                                height: 100,
                                margin: 8,
                                color: 'black',
                            }}
                        >
                            <div>
                                {note.title}
                            </div>
                            <div>
                                {note.text}
                            </div>
                        </div>
                    )
                })
            }
            </div>
        </div>
    )
}

export default NotePage;
