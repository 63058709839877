import React from 'react';
import { routes } from './routes';
import history from './history';

const Navigate = () => {
    return (
        <div
            style={{
                height: '100%',
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
            }}
        >
            {
                routes.map((route, index) => {
                    return route.showMenu && (
                        <button
                            key={index}
                            className="navigation-button"
                            onClick={() => {
                            history.push(route.path)
                        }}>
                            {route.name}
                        </button>
                    )
                })
            }
        </div>
    )
};

export default Navigate;
