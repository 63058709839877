import React from 'react';

const HomePage = () => {
    return (
        <div>
            Welcome to my geocaching solving tool
        </div>
    )
}

export default HomePage;
