import entrance from '../../imgs/Ravenclaw.png';
import {sendEnterSequence} from "../../Helpers/QuizHelpers/sendEnterSequence";
import {acceptableLetters} from "../../Helpers/QuizHelpers/acceptableLetters";
import {playStatic, playSound} from "../../Helpers/QuizHelpers/playSound";

const Ravenclaw = (props) => {
    let enterSequence = [0, ''];
    let sending = false;
    let possibleToSend = false;
    const handleKeyDown = async (e) => {
        if (!possibleToSend && (acceptableLetters.includes(e.key) || e.key === 'Enter')) {
            setTimeout(async () => {
                const c = await sendEnterSequence([], props, 'Ravenclawsjungerjulvisor')
                if (!c) {
                    return props.setCoordinates(false);
                }
            }, 10000)
        }
        if (e.keyCode === 13) {
            await sendEnterSequence(enterSequence, props, 'Ravenclawsjungerjulvisor');
        }
        if (!acceptableLetters.includes(e.key)) {
            return;
        }

        let enter = enterSequence[1];
        enter += e.key.toLowerCase();
        enterSequence[1] = enter;
    };

    const handleOnClick = () => {
        if (!sending) {
            setTimeout(async () => {
                const c = await sendEnterSequence(enterSequence, props, 'Ravenclawsjungerjulvisor')
                sending = false;
                if (c) {
                    await playSound();
                    setTimeout(() => {
                        possibleToSend = true;
                    }, 23000);
                } else {
                    props.setCoordinates(false);
                }
            }, 10000)
        }
        sending = true;
        let numberOfClicks = enterSequence[0];
        enterSequence[0] = numberOfClicks + 1;
    }

    window.onkeydown = handleKeyDown;

    return (
        <div>
            <img
                onClick={handleOnClick}
                src={entrance}
            />
        </div>
    )
};

export { Ravenclaw }
