import React, { useState, useEffect } from 'react';
import axios from "axios";

const api = process.env.REACT_APP_API;

const checkIfFib = async (string) => {
    const coords = await axios.post(`${api}/fibSolver`, {
        string,
    });
    return coords.data.coords;
};

const FibPage = () => {
    const [possibleFib, setPossibleFib] = useState(0);
    const [possibleCoords, setPossibleCoords] = useState('');

    useEffect(() => {
        async function getCoords() {
            const coords = await checkIfFib(possibleFib);
            setPossibleCoords(coords);
        }
        getCoords();
    }, [possibleFib]);

    const numberChange = (text) => {
        const number = text.target.value;
        setPossibleFib(number);
    };

    return (
        <div>
            {
                !!possibleCoords.length && possibleCoords
            }
            <input
                type="text"
                maxLength={1000}
                onChange={numberChange.bind(this)}
            />
        </div>
    )
};

export default FibPage;
