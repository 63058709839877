import fatlady from '../../imgs/FatLady.png';
import {sendEnterSequence} from "../../Helpers/QuizHelpers/sendEnterSequence";
import {acceptableLetters} from "../../Helpers/QuizHelpers/acceptableLetters";

const Gryffindor = (props) => {
    let enterSequence = '';
    const handleKeyDown = async (e) => {
        if (e.keyCode === 13) {
            await sendEnterSequence(enterSequence, props, 'Gryffindorquiztilljul');
        }
        if (!acceptableLetters.includes(e.key)) {
            return;
        }
        enterSequence += e.key.toLowerCase();
    };

    window.onkeydown = handleKeyDown;

    return (
        <div>
            <img
                src={fatlady}
            />
        </div>
    )
};

export { Gryffindor }
