import { Hufflepuff } from "../../Components/CommonroomEntries/Hufflepuff";
import { Gryffindor } from "../../Components/CommonroomEntries/Gryffindor";
import { Slytherin } from "../../Components/CommonroomEntries/Slytherin";
import { Ravenclaw } from "../../Components/CommonroomEntries/Ravenclaw";

const entrances = {
    hufflepuff: Hufflepuff,
    gryffindorquiztilljul: Gryffindor,
    slytherinplayingsanta: Slytherin,
    ravenclawsjungerjulvisor: Ravenclaw,
};

const getEntrance = (name) => {
    name = name.toLowerCase();
    return entrances[name];
};

export { getEntrance }
