import React from 'react';
import barrels from '../../imgs/barrels.PNG';
import { sendEnterSequence } from "../../Helpers/QuizHelpers/sendEnterSequence";

const barrelZones = [5, 4, 5];

const Hufflepuff = (props) => {
    let enterSequence = [];

    const setEnterSequence = async (rowIndex, index) => {
        enterSequence.push(parseInt(`${rowIndex}${index}`));
        if (enterSequence.length === 5) {
            await sendEnterSequence(enterSequence, props, 'Hufflepuff');
            enterSequence = []
            props.setEnterHouse(false);
        }
    };

    const getItems = (numberOfItems, rowIndex) => {
        let items = [];
        for(let i = 0; i < numberOfItems; i += 1) {
            items.push(<div style={{
                flex: 1,
                height: '10vh',
            }}
            onClick={() => {
                setEnterSequence(rowIndex, i);
            }}
            />)
        }
        return items;
    };

    return (
        <div
            style={{
                backgroundColor: 'green',
                height: '30vh',
            }}
        >
            <img
                style={{
                    marginBottom: 0,
                    height: '30vh',
                    padding: 0,
                }}
                src={barrels}
            />
            <div
                style={{
                    width: '100%',
                    height: '30vh',
                    position: 'relative',
                    marginTop: '-30vh',
                    padding: 0,
                }}
            >
                {barrelZones.map((zone, index) => {
                    return (
                        <div
                            style={{
                                width: '100%',
                                height: '10vh',
                                display: 'flex',
                                flexDirection: 'row',
                            }}
                        >
                            {getItems(zone, index)}
                        </div>
                    )
                })}

            </div>
        </div>
    )
}

export { Hufflepuff };
