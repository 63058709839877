import { HomePage } from './Pages/HomePage';
import { FibPage } from "./Pages/FibPage";
import { ReverseWIGPage } from "./Pages/ReverseWIGPage";
import { NotePage } from './Pages/NotePage';
import { QuizPage } from './Pages/QuizPage';
import {ChristmasPage} from "./Pages/ChristmasPage";
import Erised from "./Pages/Erised";

export const routes = [
    {
        path: '/main',
        name: 'Home',
        exact: true,
        component: HomePage,
        requiresAuth: true,
        showMenu: true,
    },
    {
        path: '/fib',
        name: 'Fibonacci solver',
        exact: false,
        component: FibPage,
        requiresAuth: true,
        showMenu: true,
    },
    {
        path: '/rev',
        name: 'Reverse WIG solver',
        exact: false,
        component: ReverseWIGPage,
        requiresAuth: true,
        showMenu: true,
    },
    {
        path: '/notes',
        name: 'Note page',
        exact: false,
        component: NotePage,
        requiresAuth: true,
        showMenu: true,
    },
    {
        path: '/quiz',
        name: 'Quiz page',
        exact: false,
        component: QuizPage,
        requiresAuth: false,
        showMenu: false,
    },
    {
        path: '/erised',
        name: 'Mirror',
        exact: false,
        component: Erised,
        requiresAuth: false,
        showMenu: false,
    },
    {
        path: '/christmas',
        name: 'Christmas page',
        exact: false,
        component: ChristmasPage,
        requiresAuth: false,
        showMenu: false,
    }
];

export default routes;
