import './App.css';
import { Router, Switch, Route, useLocation } from 'react-router-dom';
import React, { useState, useEffect } from "react";
import axios from 'axios';
import routes from "./routes";
import history from './history';
import Navigate from "./navigate";

const api = process.env.REACT_APP_API;

const get404 = async () => {
    await axios.get(`${api}/users`);
};


const usePageView = () => {
    let location = useLocation();
    React.useEffect(() => {
        let truth = routes.find(o => o.path === location.pathname);
        if (!truth) {
            get404();
        }
    }, [location]);
};

const App = () => {
    const [showPage, setShowPage] = useState(true);
    const [location, ] = useState(useLocation());
    const [hideSideBar, setHideSidebar] = useState(true);
    useEffect(() => {
        let truth = !!routes.find(o => o.path === location.pathname);
        if (!location.pathname.includes('quiz') && !location.pathname.includes('christmas') && !location.pathname.includes('erised')) {
            setHideSidebar(false);
        }
        setShowPage(truth);
    }, [location]);
    usePageView();
    return showPage && (
        <div className="App">
            <header className="App-header">
                {
                    !hideSideBar && (
                        <div
                            style={{
                                position: 'absolute',
                                left: 0,
                                display: 'flex',
                                flexDirection: 'column',
                                height: '100%',
                            }}
                        >
                            <Navigate/>
                        </div>
                    )
                }
                <div
                    className="App-header"
                    style={{
                        width: '80%',
                    }}
                >
                    <Router
                        history={history}
                    >
                        <Switch>
                            {
                                routes.map((route, index) => {
                                    return <Route path={route.path} component={route.component} key={index} />
                                })
                            }
                        </Switch>
                    </Router>
                </div>
            </header>
        </div>
    );
}

export default App;
